import type React from "react";

import classNames from "classnames";

const BasicPage = ({
  wide,
  header,
  children,
}: {
  wide?: boolean;
  header?: React.ReactNode;
  children: React.ReactNode;
}) => (
  <div
    className={classNames("mx-auto px-4 pb-6 flex flex-col min-h-dvh", {
      "max-w-md": !wide,
      "max-w-2xl": wide,
    })}
  >
    <header className="text-center py-6">
      <Spacer header={header} />
      <img
        className="inline-block"
        src="/health-learn.png"
        alt="HealthLearn"
        width={250}
        height={70}
      />
    </header>
    <main className="flex-grow flex flex-col">{children}</main>
  </div>
);

// Spacer matches the height of feedback button if no header is provided...
// this bumps the logo down on the loading screen and prevents jitter once e.g. the login route loads
const Spacer = ({ header }: { header?: React.ReactNode }) =>
  header ?? <div className="h-8" />;

export default BasicPage;
